import { useEffect, useId, useRef, useState } from 'react'

import LabelFloating from './label-floating'

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  initialValue?: string
  onUpdate?: (arg: string, ref?: HTMLInputElement) => string | void
  tooltip?: string
  autoFocus?: boolean
  fieldsetCss?: string
  labelCss?: string
}

export default function TextField(props: TextFieldProps): JSX.Element {
  const { autoFocus, className, label, initialValue, onUpdate, tooltip, fieldsetCss, labelCss, ...inputProps } = props

  const ref = useRef<HTMLInputElement>()
  const uid = useId()
  const [search, setSearch] = useState()

  useEffect(() => {
    setSearch(new URLSearchParams(window.location).get('search'))
  }, [])

  const [hasFocus, setFocus] = useState(false)
  const [input, setInput] = useState('')

  useEffect(() => {
    autoFocus && ref.current?.focus()
    const defVal = new URLSearchParams(search).get(inputProps.name) || (initialValue as string)
    if (defVal) setInput(defVal)
  }, [])

  return (
    <LabelFloating
      label={label}
      uid={uid}
      hasFocus={hasFocus}
      hasInput={input?.length > 0}
      required={inputProps.required}
      name={inputProps.name}
      tooltip={tooltip}
      fieldsetCss={fieldsetCss}
      labelCss={labelCss}
    >
      <input
        ref={ref}
        id={uid}
        defaultValue={input}
        onChange={(event) => {
          let updatedValue: string | undefined
          if (onUpdate) updatedValue = onUpdate(event.target.value, ref.current) || event.target.value
          setInput(updatedValue || event.target.value)
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className={`${className} h-11 lg:h-14 px-3 py-2 text-indigoBlue text-lg w-full outline-none truncate bg-inherit`}
        aria-required={inputProps.required ? 'true' : 'false'}
        {...inputProps}
      />
    </LabelFloating>
  )
}
